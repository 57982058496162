import React, { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Item, { ItemProps } from "./Item";
import {HolderOutlined} from '@ant-design/icons';
import './App.css';


const SortableItem: FC<ItemProps> = (props) => {
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
        background: "violet",
        border:"none",
    };

    return (
        <div className="box">
            <button {...attributes} {...listeners} className="handler">
                <HolderOutlined />
            </button>
            <Item
                ref={setNodeRef}
                style={style}
                withOpacity={isDragging}
                {...props}
            />
        </div>
    );
};

export default SortableItem;
