import React, { forwardRef, HTMLAttributes, CSSProperties } from 'react';
import {Card} from 'antd'
import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import {makeStyles} from "@mui/styles";

import './App.css'
export type ItemProps = HTMLAttributes<HTMLDivElement> & {
    id: string;
    genre: string;
    visible: string;
    img: string;
    title: string;
    link: string;
    withOpacity?: boolean;
    isDragging?: boolean;
    viewMode: Number;
    onDelete: (event:any) => void;
    onEdit: (event:any) => void;    
    onChangeVisible: (event:any) => void;    
};


const Item = forwardRef<HTMLDivElement, ItemProps>(({ id, img, title, genre, link, visible, withOpacity, isDragging, style, viewMode, onDelete, onEdit,onChangeVisible, ...props }, ref) => {
    const inlineStyles: CSSProperties = {
        opacity: withOpacity ? '0.5' : '1',
        transformOrigin: '50% 50%',
        width: '150px',
        height: '300px',
        borderRadius: '10px',
        cursor: isDragging ? 'grabbing' : 'grab',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: isDragging  ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px' : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
        transform: isDragging ? 'scale(1.05)' : 'scale(1)',
        ...style,
    };

    if (viewMode===1){
        inlineStyles.height='120px';
    }

    const [openDialog,setOpenDialog] =React.useState(false);

    const handleClickOpen =()=>{
        setOpenDialog(true);
    }
    const handleClose=()=>{
        setOpenDialog(false);
    }

    const useStyles = makeStyles(() => ({
        chipCustom: {
            borderRadius: '20px', 
            '& .MuiChip-label': {fontSize: 8}, 
        },      
    }));

    const onOpenLink =(link:string)=>{
        if(link!=""){
            window.open(link, '_blank')
        }
    }
    
    const customChipClass = useStyles();


    return(
         <div ref={ref} style={inlineStyles} {...props}   >
            {
                viewMode===0 && //フルモード
                <div className={visible==="1"?'card card-bg-1':'card card-bg-2'} onDoubleClick={()=>{onOpenLink(link)}}>
                    <div className='cover-img-back'>
                        <img src={img}  className='cover-img' />
                    </div>
                    <Chip label={genre}  className={customChipClass.chipCustom} style={{position:"absolute",top:"60%",margin:"8px" }} />
                    <Box  className="card-name">
                        <p> {title} </p>
                    </Box>
                    <DeleteIcon onClick={handleClickOpen} className="delete-button" />
                    <EditIcon onClick={()=>{onEdit(id)}} className='edit-button'/>
                    {
                        visible==="1"?
                        <VisibilityIcon onClick={()=>{onChangeVisible(id)}}className='visible-button' />
                        :<VisibilityOffIcon onClick={()=>{onChangeVisible(id)}}className='visible-button' /> 
                    }
                    <Dialog
                        open={openDialog}
                        onClose={handleClose}
                    >
                        <DialogContent>
                            <img src={img} className='dialog-img'/>
                            <Typography>{title} を消去しますか？</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{onDelete(id);handleClose()}}>消す</Button>
                        </DialogActions>
                    </Dialog>   
                </div>
            }
            {
                viewMode===1 && //省略モード

                <div className={visible==="1"?'card card-bg-1':'card card-bg-2'}>
                <Chip label={genre}  className={customChipClass.chipCustom} style={{position:"absolute",top:"18%",margin:"8px" }} />
                <Box  className="card-name-mode1">
                    <p> {title} </p>
                </Box>
                <DeleteIcon onClick={handleClickOpen} className="delete-button" />
                <EditIcon onClick={()=>{onEdit(id)}} className='edit-button'/>
                <VisibilityIcon className='visible-button' />
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                >
                    <DialogContent>
                        <Typography>{title} を消去しますか？</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{onDelete(id);handleClose()}}>消す</Button>
                    </DialogActions>
                </Dialog>   
            </div>



            }
        </div>
    );
});

export default Item;
