import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
    DragStartEvent,
    DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import Grid from './Grid';
import SortableItem from './SortableItem';
import Item from './Item';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import './App.css';
import {Layout,Menu,Button} from 'antd';
import {ExportOutlined ,ImportOutlined, CameraOutlined, LeftOutlined, MenuOutlined, ShareAltOutlined, PictureOutlined} from '@ant-design/icons'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { MenuItem } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {SpeedDial,SpeedDialAction}  from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import html2canvas from 'html2canvas';
import { Interface } from 'readline';

const {Header, Sider, Content } = Layout;

const App: FC = () => {
    const [items, setItems] = useState(Array.from({ length: 20 }, (_, i) => (i + 1).toString()));
    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setActiveId(event.active.id);
    }, []);
    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            setFavorites((favorites) => {
                const oldIndex = favorites.indexOf(favorites.find((f)=>f.id===active.id)!);
                const newIndex = favorites.indexOf(favorites.find((f)=>f.id===over!.id)!);

                return arrayMove(favorites, oldIndex, newIndex);
            });
        }

        setActiveId(null);
    }, []);
    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    const [favorites,setFavorites]=useState(
        [
            {id:"1",title:"電脳コイル",genre:"アニメ",img:"https://i.imgur.com/kvCB1OR.png",link:"",visible:"1"},
            {id:"2",title:"インファナル・アフェア",genre:"映画",img:"https://i.imgur.com/3QdpKaO.png",link:"",visible:"1"}
        ]
    );

    const [genrelist,setGenrelist]=useState(["All"]);

    useEffect(()=>{
        loadJSONfromLocalStorage();
    },[]);

    useEffect(()=>{
        const temp = favorites.map((item)=>item.genre);
        const result = new Set(temp);
        const resultArr = Array.from(result);
        console.log(resultArr);
        setGenrelist(["All",...resultArr]);

        //localStorageに保存
        saveJson2LocalStorage();
    },[favorites]);

    const handleDeleteItem = (id:string) =>{
        var tempFav= favorites.slice();
        tempFav=tempFav.filter((favorite,index)=>(favorite.id!=id));
        tempFav.map(function(value,index,array){
            value.id=(index+1).toString();
            array[index]=value;
        });
        setFavorites(tempFav);
    }

    const handleChangeVisible=(id:string)=>{
        var tempFav= favorites.slice();
        tempFav.find((f)=>f.id===id)!.visible= reverseVisible(tempFav.find((f)=>f.id===id)!.visible);
        setFavorites(tempFav);
    }

    const reverseVisible=(src:string)=>{
        if (src=="0")
            return "1";
        else
            return "0";

    }


    const handleAddItem=()=>{
        const id:string = (favorites.length+1).toString();
        var tempFav=favorites.slice();

        tempFav.push({id:id,title:newName,genre:newGenre,img:newImage,link:newLink,visible:"0"});
        setFavorites(tempFav);
    }

    const handleEditItem=()=>{
        const id:string = editedID;
        var tempFav=favorites.slice();
        tempFav.find((f)=>f.id===editedID)!.title=newName;
        tempFav.find((f)=>f.id===editedID)!.genre=newGenre;
        tempFav.find((f)=>f.id===editedID)!.img=newImage;
        tempFav.find((f)=>f.id===editedID)!.link=newLink;       
        setFavorites(tempFav);
    }



    const [openDialog,setOpenDialog] =React.useState(false);
    const [openEditDialog,setOpenEditDialog] =React.useState(false);
    const [openSpeedDial,setOpenSpeedDial] = React.useState(false);
    

    const handleOpenDialog =()=>{
        setNewName("");
        setNewGenre("");
        setNewImage("");
        setNewLink("");
        setNameInputError(false);
        setOpenDialog(true);
    }
    const handleCloseDialog=()=>{
        setOpenDialog(false);
    }

    
    const handleOpenEditDialog =(id:string)=>{
        setNewName(favorites.find((f)=>f.id===id)!.title);
        setNewGenre(favorites.find((f)=>f.id===id)!.genre);
        setNewImage(favorites.find((f)=>f.id===id)!.img);
        setNewLink(favorites.find((f)=>f.id===id)!.link);
        setEditedID(id);
        setNameInputError(false);
        setOpenEditDialog(true);
    }
    const handleCloseEditDialog=()=>{
        setOpenEditDialog(false);
    }
    

    const [newName,setNewName] = React.useState("");
    const [newGenre,setNewGenre] = React.useState("");
    const [newImage,setNewImage] = React.useState("");
    const [newLink,setNewLink] = React.useState("");
    const [editedID,setEditedID] = React.useState("0");
    
    const [nameInputError,setNameInputError] = React.useState(false);
    const [selectedGenre,setSelectedGenre]=React.useState("All");
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [uploadImage,setUploadImage]= React.useState("");

    const mainMenu=[
        {key:"1",icon:"",label:"Home"},
        {key:"2",icon:"",label:"Settings"},
    ]

    const [viewMode,setviewMode]= React.useState(0);


    const inputValidation=():boolean=>{
        let valid = true;
        
        const n=inputRef?.current;
        if(n){
            setNameInputError(!n.validity.valid);
            valid= valid && n.validity.valid;
        }
        return valid;
    }

    const saveJson=()=>{
        const strItem = JSON.stringify(favorites);
        const blobItem = new Blob([strItem],{type:'application/json'});
        let dum_a = document.createElement('a');
        document.body.appendChild(dum_a);
        dum_a.href=window.URL.createObjectURL(blobItem);
        dum_a.download="fav.json";
        dum_a.click();
        document.body.removeChild(dum_a);
    }

    const saveJson2LocalStorage=()=>{
        const strItem = JSON.stringify(favorites);
        localStorage.setItem("favlog",strItem);
    }

    const loadLocalJSON=(e:any)=>{
        // console.log("event")
        let fileData = e.target.files[0];
        let reader = new FileReader();

        reader.onload = function(ev:any) {       
            let parse_result = JSON.parse(ev.target.result);
            console.log(parse_result);
            setFavorites(parse_result);
        }
        
        reader.readAsText(fileData);
    }


    const loadJSONfromLocalStorage=()=>{
        // console.log("event")
        if (window.localStorage) {
            let parse_result = JSON.parse(localStorage.getItem("favlog")||"{}");
            if (parse_result.length !== undefined)
            {
                console.log("localdata:"+parse_result.length);
                setFavorites(parse_result);
            }
            else
            {
                console.log("no localdata");
            }
        }
    }

    const handleSelectGenre=(e:SelectChangeEvent)=>{
        setSelectedGenre(e.target.value as string);
    }

    type Anchor = "left";
    const [drawerState,setDrawerState] = React.useState(false); 
    const handleDrawerOpen=()=>{
        setDrawerState(true);
    }

    const handleChangeViewMode=(
        e: React.MouseEvent<HTMLElement>,
        newMode: string | null,
    )=>{
        setviewMode(Number(newMode)!);
    }

    const capture_target = useRef<HTMLDivElement>(null);

    const handleCaptureBoard =()=>{
        html2canvas(capture_target.current!,
            {
                useCORS:true,
                // windowWidth:1980,
                // width:1980,
                scale:2,
                onclone: function(doc){doc.getElementById("hidden-logo")!.style.display='block'}
            }
        
        ).then((canvas)=>
        {
            const link = document.createElement('a');
            link.href = canvas.toDataURL();
            link.download="export_image.png";
            link.click();
        });
    }

    const img2Base64_Shrink=(file:File)=>{
        const reader = new FileReader();
        const MIN_SIZE= 150;
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let image = new Image();
        let dstWidth, dstHeight;
        
        image.crossOrigin="Anonymous";

        reader.onload=(event)=>{
            image.onload=()=>{
                if(image.width>image.height){
                    dstWidth=MIN_SIZE;
                    dstHeight=image.height*MIN_SIZE/image.width;
                }
                else{
                    dstHeight=MIN_SIZE;
                    dstWidth=image.width*MIN_SIZE/image.height;
                }
                canvas.width = dstWidth;
                canvas.height=dstHeight;
                ctx!.drawImage(image!,0,0,image!.width,image!.height,0,0,dstWidth,dstHeight);
                const base64=canvas.toDataURL();
                setUploadImage(base64);
                console.log(base64);
                const base64_body=base64.replace(new RegExp('data.*base64,'),'');
                // const result=uploadImageCallBack(base64_body);
                // console.log(result);

                uploadImageCallBack(base64_body).then((resultlink)=>{
                    console.log(resultlink);
                    setNewImage(resultlink as string);
                })
            }
            image.src=event.target?.result as string;
            
        }

        reader.readAsDataURL(file);

    }

    const handleUploadImage= (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null || event.target.files.length === 0) {
           return;
        }
        const imgURI=img2Base64_Shrink(event.target.files[0]);
        // uploadImageCallBack(event.target.files[0]);
    }

    const uploadImageCallBack =(databody:any) =>{
        return new Promise(
          (resolve, reject) => {
            const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
            // xhr.responseType='json';
            var reader = new FileReader();
            reader.onloadend = function() {
                console.log(reader.result);
            //   callback(reader.result);
            }
            xhr.open('POST', 'https://api.imgur.com/3/upload');
            xhr.setRequestHeader('Authorization', 'Client-ID c6faccffa41d8e0');
            const data = new FormData(); 
            data.append('image', databody);
            data.append('type','base64')
            xhr.send(data);
            xhr.addEventListener('load', () => {
              const response = JSON.parse(xhr.responseText);
              resolve(response.data.link);
            });
            xhr.addEventListener('error', () => {
                console.log(xhr.responseText);
              const error = JSON.parse(xhr.responseText);
              reject(error);
            });
          },
        );
      }


    return (
            <div className='app'>
                <MenuOutlined onClick={handleDrawerOpen} className="menubutton" style={{color:'var(--color-4)'}}/>
                <Drawer
                    sx={{
                    width: '200px',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: '200px',
                        boxSizing: 'border-box',
                        backgroundColor:'var(--color-4)',
                    },
                    textAlign:'left',
                    }}
                    // variant="persistent"
                    anchor="left"
                    open={drawerState}
                    onClose={()=>{setDrawerState(false)}}
                >
                    <label className='logo-drawer'>
                        os
                    </label>
                    <LeftOutlined onClick={()=>{setDrawerState(false)}} className="menubutton" style={{color:'var(--color-2)'}}/>
                    <label htmlFor="import" className='import'>
                        <div >
                            <ImportOutlined />
                            &nbsp; 読み込み
                        </div>
                        <input type="file" id="import" onChange={loadLocalJSON} style={{display:"none"}}/>
                    </label>
                    <div className='export'onClick={saveJson}>
                        <ExportOutlined  />
                        &nbsp; 書き出し
                    </div>
                </Drawer>
                
                <label className='logo'>
                    oshibox.com
                </label>
                <label className='logo-copy'>
                    ～ わたしの"推し"を集めよう ～
                </label>

                {/* ジャンルフィルタ */}
                <div className='menubar'>
                <Select 
                    className="genre-select" 
                    value={selectedGenre}
                    onChange={handleSelectGenre}
                >
                {
                    genrelist.map((item,index)=>{
                        return(
                        <MenuItem key={index} value={item}>
                                {item}
                        </MenuItem> 
                        );
                    })
                    } 
                </Select>
                <RestartAltIcon  className='reset-genre' onClick={()=>{setSelectedGenre('All')}} />
                
                {/* 表示形式 */}
                <ToggleButtonGroup
                    color='primary'
                    value={viewMode}
                    exclusive
                    onChange={handleChangeViewMode}
                    aria-label="viewmode"
                    className='viewmodeselect'
                    >
                    <ToggleButton value="0" aria-label="mode0">
                        大
                    </ToggleButton>
                    <ToggleButton value="1" aria-label="mode1">
                        小
                    </ToggleButton>
                </ToggleButtonGroup>
                </div>

                <div ref={capture_target} className="main-area">
                
                <div id="hidden-logo" style={{display:'none'}}>
                    <label className='hidden-logo'>
                        oshibox.com
                    </label>
                </div>

                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragCancel={handleDragCancel}
                >
                
                { !openDialog &&
                    <SortableContext items={favorites} strategy={rectSortingStrategy}>
                        <Grid columns={Math.floor((window.innerWidth) /170)}>
                            { selectedGenre!=='All'  &&
                                favorites
                                .filter(favorite=>favorite.genre===selectedGenre)
                                .map((favorite) => (
                                <SortableItem key={favorite.id} id={favorite.id} genre={favorite.genre} visible={favorite.visible} img={favorite.img} title={favorite.title} link={favorite.link} viewMode={viewMode} onDelete={(id:string)=>handleDeleteItem(id)} onEdit={(id:string)=>handleOpenEditDialog(id)} onChangeVisible={(id:string)=> handleChangeVisible(id)}  />
                            ))}
                            { selectedGenre==='All' && 
                                favorites
                                .map((favorite) => (
                                <SortableItem key={favorite.id} id={favorite.id} genre={favorite.genre} visible={favorite.visible} img={favorite.img} title={favorite.title} link={favorite.link} viewMode={viewMode} onDelete={(id:string)=>handleDeleteItem(id)} onEdit={(id:string)=>handleOpenEditDialog(id)} onChangeVisible={(id:string)=> handleChangeVisible(id)}/>
                            ))}
                            
                        </Grid>
                    </SortableContext>
                }
                    <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                        {activeId ? 
                            <Item id={activeId} title={favorites.find((f)=>f.id===activeId)!.title} genre={favorites.find((f)=>f.id===activeId)!.genre}  visible={favorites.find((f)=>f.id===activeId)!.visible} viewMode={viewMode} img={favorites.find((f)=>f.id===activeId)!.img}   link={favorites.find((f)=>f.id===activeId)!.link } onDelete={()=>null} onEdit={()=>null} onChangeVisible={()=>null} isDragging />
                        
                        : null}
                    </DragOverlay>
                </DndContext>  
                </div>
                <button className='plusbutton' onClick={handleOpenDialog}>新規追加</button>
                <Backdrop open={openSpeedDial} />
                <SpeedDial
                    ariaLabel='speeddial'
                    sx={{
                        position:'fixed', 
                        bottom:'100px', 
                        right: '10vw',     
                        '& .MuiFab-primary': { width: 75, height: 75, fontSize: 25} 
                    }}
                    icon={<ShareAltOutlined />}
                    open={openSpeedDial}
                    onOpen={()=>{setOpenSpeedDial(true)}}
                    onClose={()=>{setOpenSpeedDial(false)}}                   
                    direction='up'
                    FabProps={{
                        sx:{
                            bgcolor: 'rgba(0,24,124,0.2)',
                            '&:hover':{
                                bgcolor: 'var(--color-4)'
                            }
                        }
                    }}
                >
                    <SpeedDialAction
                        key="画像として保存"
                        icon={<PictureOutlined />}
                        tooltipTitle="画像として保存"
                        onClick={handleCaptureBoard}
                        tooltipOpen
                        />
                </SpeedDial>

                {/* 新規作成ダイアログ */}
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                >
                    <DialogContent sx={{maxWidth:250}}>
                        <DialogTitle className='create-title'>新規追加</DialogTitle>
                        <TextField
                            autoFocus
                            error ={nameInputError}
                            helperText={nameInputError&&"名前を正しく入れて下さい"}
                            margin="dense"
                            id="name"
                            label="名前"
                            fullWidth
                            variant='filled'
                            value={newName}
                            inputProps={{required:true,minLength:1,maxLength:30}}
                            inputRef ={inputRef}
                            onChange={(event)=>{
                                        setNewName(event.target.value);
                                    }}
                        />
                        <TextField
                            margin="dense"
                            id="genre"
                            label="ジャンル"
                            fullWidth
                            variant='filled'
                            value={newGenre}
                            inputProps={{required:true,minLength:1,maxLength:30}}
                            onChange={(event)=>{
                                        setNewGenre(event.target.value);
                                    }}
                        />
                        <label  htmlFor="upload">
                        <div className='create-img-back'>
                            <img 
                              src={newImage}
                              onError={(e)=>{e.currentTarget.src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd2NAjCcjjk7ac57mKCQvgWVTmP0ysxnzQnQ&usqp=CAU"}}
                              className="create-img"
                            />
                        </div>
                        </label>
                        <input
                            type="file"
                            name="upload"
                            id="upload"
                            hidden
                            accept="image/*"
                            onChange={handleUploadImage}
                        />
                        <TextField
                            margin="dense"
                            id="link"
                            label="リンクURL(任意)"
                            fullWidth
                            variant='filled'
                            value={newLink}
                            inputProps={{required:true,minLength:1,maxLength:30}}
                            onChange={(event)=>{
                                        setNewLink(event.target.value);
                                    }}
                        />
                        <TextField
                            margin="dense"
                            id="image"
                            label="画像URL"
                            fullWidth
                            variant='filled'
                            value={newImage}
                            onChange={(event)=>setNewImage(event.target.value)}
                            style={{display:"none"}}
                        />
                    </DialogContent>
                    <DialogActions 
                            className="create-button">
                        <Button 
                            className='create-ok-button'
                            onClick={()=>{
                                if(inputValidation()){
                                    handleAddItem(); handleCloseDialog();
                                }
                            }}
                        >
                            作成
                        </Button>
                    </DialogActions>
                </Dialog>            

                {/* 編集ダイアログ */}
                <Dialog
                    open={openEditDialog}
                    onClose={handleCloseEditDialog}
                >
                    <DialogContent sx={{maxWidth:250}}>
                        <DialogTitle className='create-title'>内容編集</DialogTitle>
                        <TextField
                            autoFocus
                            error ={nameInputError}
                            helperText={nameInputError&&"名前を正しく入れて下さい"}
                            margin="dense"
                            id="name"
                            label="名前"
                            fullWidth
                            variant='filled'
                            value={newName}
                            inputProps={{required:true,minLength:1,maxLength:30}}
                            inputRef ={inputRef}
                            onChange={(event)=>{
                                        setNewName(event.target.value);
                                    }}
                        />
                        <TextField
                            margin="dense"
                            id="genre"
                            label="ジャンル"
                            fullWidth
                            variant='filled'
                            value={newGenre}
                            inputProps={{required:true,minLength:1,maxLength:30}}
                            onChange={(event)=>{
                                        setNewGenre(event.target.value);
                                    }}
                        />
                        <label  htmlFor="upload">
                        <div className='create-img-back'>
                            <img 
                              src={newImage}
                              onError={(e)=>{e.currentTarget.src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd2NAjCcjjk7ac57mKCQvgWVTmP0ysxnzQnQ&usqp=CAU"}}
                              className="create-img"
                            />
                        </div>
                        </label>
                        <input
                            type="file"
                            name="upload"
                            id="upload"
                            // ref={componentRef}
                            hidden
                            accept="image/*"
                            onChange={handleUploadImage}
                        />
                        <TextField
                            margin="dense"
                            id="link"
                            label="リンクURL(任意)"
                            fullWidth
                            variant='filled'
                            value={newLink}
                            inputProps={{required:true,minLength:1,maxLength:30}}
                            onChange={(event)=>{
                                        setNewLink(event.target.value);
                                    }}
                        />
                        <TextField
                            margin="dense"
                            id="image"
                            label="画像URL"
                            fullWidth
                            variant='filled'
                            value={newImage}
                            onChange={(event)=>setNewImage(event.target.value)}
                            style={{display:"none"}}
                        />
                    </DialogContent>
                    <DialogActions 
                            className="create-button">
                        <Button 
                            className='create-ok-button'
                            onClick={()=>{
                                if(inputValidation()){
                                    handleEditItem(); handleCloseEditDialog();
                                }
                            }}
                        >
                            更新
                        </Button>
                    </DialogActions>
                </Dialog>            

            </div>

    );
};

export default App;
